import dayjs from 'dayjs';
import React, { useContext } from 'react';

// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazyload';
import i18n from '../../i18n';
import { trackBanner } from '../../utils/datalayer';
import EventIcon from '../EventIcon/EventIcon';
import Icon from '../Icon';
import { TrackingContext } from '../StreamField/StreamField';
import styles from './InTheSpotlight.module.scss';

const InTheSpotlight = ({
    title,
    preamble,
    image,
    href,
    venue = '',
    category,
    categories = [],
    occurrences = [],
    startTime,
    noFutureEvent = false,
    endTime,
}) => {
    if(noFutureEvent) {
        return null;
    }

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    let formattedCategories = '';

    if (typeof categories === 'string') {
        formattedCategories = categories;
    } else if (categories && categories.length > 0) {
        formattedCategories = categories.join(',');
    }

    const bannerLevel = useContext(TrackingContext);

    const extraProps = {
        onClick: () => {
            trackBanner(bannerLevel, formattedCategories, title, {
                modulHeadline: 'In the spotlight',
            });
        },
    };

    let firstDate;
    let lastDate;
    let dateStr = null;

    if (
        occurrences.length > 0 &&
        occurrences[0]?.date &&
        occurrences[occurrences.length - 1]?.date
    ) {
        const firstDateObj = dayjs(occurrences[0]?.date);
        firstDate = firstDateObj.format('D MMM');
        const lastDateObj = dayjs(occurrences[occurrences.length - 1]?.date);
        dateStr = `${firstDate}`;
        if (!firstDateObj.isSame(lastDateObj)) {
            lastDate = lastDateObj.format('D MMM YYYY');
            dateStr = `${firstDate} — ${lastDate}`;
        }

        if (!!startTime && !!endTime) {
            dateStr += `, ${startTime
                .split(':')
                .slice(0, 2)
                .join(':')} – ${endTime.split(':').slice(0, 2).join(':')}`;
        }
    }

    const classes = classNames(styles['InTheSpotlight'], {
        [styles['InTheSpotlight--InView']]: inView,
    });

    return (
        <div ref={ref} className={classes}>
            <div className={styles['InTheSpotlight__Container']}>
                {!!category?.title && (
                    <div
                        className={classNames(
                            styles['InTheSpotlight__CategoryContainer']
                        )}>
                        <EventIcon icon={category.icon} />
                        <span
                            className={styles['InTheSpotlight__CategoryText']}>
                            {category.title}
                        </span>
                    </div>
                )}
                {image && image.renditions && (
                    <LazyLoad offset={400} once>
                        <img
                            sizes="343px"
                            width={253}
                            className={styles['InTheSpotlight__Image']}
                            height={243}
                            srcSet={`${image.renditions.medium.src} 600w,
                                     ${image.renditions.large.src} 1200w`}
                            src={image.renditions.medium.src}
                            alt={image.alt}
                            style={{
                                objectPosition:
                                    image.focal.x + ' ' + image.focal.y,
                            }}
                        />
                    </LazyLoad>
                )}
                <div className={styles['InTheSpotlight__Info']}>
                    <div className={styles['InTheSpotlight__Tag']}>
                        {i18n.t('InTheSpotlight.title')}
                    </div>
                    <h2 className={styles['InTheSpotlight__Title']}>{title}</h2>
                    <div
                        className={styles['InTheSpotlight__Preamble']}
                        dangerouslySetInnerHTML={{ __html: preamble }}
                    />
                    <div className={styles['InTheSpotlight__Meta']}>
                        {!!dateStr && (
                            <div className={styles['InTheSpotlight__Date']}>
                                <Icon
                                    size="small"
                                    type="calender"
                                    color={'gray700'}
                                />
                                {dateStr}
                            </div>
                        )}
                        {!!venue && (
                            <div className={styles['InTheSpotlight__Venue']}>
                                <Icon
                                    size="sm"
                                    type="place"
                                    color={'gray700'}
                                />
                                {venue}
                            </div>
                        )}
                    </div>

                    <div
                        aria-hidden={true}
                        className={styles['InTheSpotlight__ReadMore']}>
                        {i18n.t('generic.readMore')}
                        <Icon size="sm" type="arrow" color={'pink'} />
                    </div>
                </div>
                <a
                    {...extraProps}
                    className={styles['InTheSpotlight__Href']}
                    href={href}>
                    <span className="sr-only">{title}</span>
                </a>
            </div>
        </div>
    );
};

InTheSpotlight.propTypes = {};

InTheSpotlight.defaultProps = {};

export default InTheSpotlight;
